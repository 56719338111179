import { useModule } from '@/controllers/manager'
import { messageAlerts, customAlert } from '@/middlewares/responseHandler'
import store from '@/store/index'

export default {
  namespaced: true,
  state: {
    module: 'empleado',
    loading: false,
    empleados: null,
  },
  getters: {},
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_EMPLEADOS(state, payload) {
      state.empleados = payload
    },
    SET_EMPLEADO_AS_SELECTED(state, payload) {
      state.empleados[payload.index].isSelected = payload.value
    },
    SET_ALL_EMPLEADOS_SELECTED_STATUS(state, payload) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < state.empleados.length; i++) {
        state.empleados[i].isSelected = payload
      }
    },
  },
  actions: {
    async fetchEmpleados({ state, commit }, payload) {
      commit('SET_LOADING', true)
      store.commit('app/RESET_ALERT')
      const controller = useModule(state.module)
      try {
        return await controller.getAll(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchEmpleado({ state, commit }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        return await controller.getById(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async addEmpleado({ commit, state }, empleadoData) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.create(empleadoData)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async updateEmpleadoUniformStatus({ commit, state }, { empleadoId, uniformStatus }) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.updateEmpleadoUniformStatus(empleadoId, uniformStatus)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async importEmpleados({ commit, state }, file) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        const res = await controller.importar(file)
        let parsedMsg = JSON.stringify(res.data.message)
        parsedMsg = parsedMsg.replace(/{|}|:/g, '')
        parsedMsg = parsedMsg.replace(/"/g, ' ')
        store.commit('app/SET_ALERT', customAlert(200, parsedMsg, 'success', 'CheckIcon'))
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
