<template>
  <b-modal
    v-model="modal"
    :title="title"
    ok-title="Ok"
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    @close="close"
    @cancel="close"
    @ok="callback"
    :ok-only="isOkOnly"
  >
    <b-alert show :variant="variant">
      <p style="padding:10px">{{ message }}</p>
    </b-alert>
  </b-modal>
</template>

<script>
import { BModal, BAlert } from "bootstrap-vue";

export default {
  components: {
    BModal,
    BAlert,
  },
  props: {
    show: {
      type: Boolean,
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    isOkOnly: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {
      modal: false,
    };
  },
  watch: {
    show() {
      this.modal = this.show;
    },
  },
  methods: {
    close() {
      this.modal = false;
      this.$emit("close");
    },
    callback() {
      this.modal = false;
      this.$emit("action");
    },
  },
};
</script>
